<template> </template>

<script>
import { signRedirect } from '@/api/contract';
export default {
  beforeRouteEnter(to, from, next) {
    let item = to.query;
    if (item.status === 'complete') {
      if (item.resultData === 'verify') {
        next({
          path: '/CertifiedContract',
          query: { businessFlowId: item.businessFlowId },
        });
      } else if (item.resultData === 'default') {
        next({ path: '/' });
      } else {
        let link = document.createElement('a');
        link.style.display = 'none';
        link.href = item.resultData;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   let item = to.query;
  //   if (to.path === '/block') {
  //     signRedirect(item.businessFlowId).then((res) => {
  //       if (res.status === 'complete') {
  //         if (res.resultData === 'verfiy') {
  //           next({
  //             path: '/CertifiedContract',
  //             query: { businessFlowId: item.businessFlowId },
  //           });
  //         } else if (res.resultData === 'default') {
  //           next({ path: '/' });
  //         } else {
  //           let link = document.createElement('a');
  //           link.style.display = 'none';
  //           link.href = res.resultData;
  //           document.body.appendChild(link);
  //           link.click();
  //           document.body.removeChild(link);
  //         }
  //       }
  //     });
  //   }
  // },
  // beforeCreate() {
  //   let item = this.$route.query;
  //   signRedirect(item.businessFlowId).then((res) => {
  //     if (res.status === 'complete') {
  //       if (res.resultData === 'verfiy') {
  //         this.$router.push({
  //           path: '/CertifiedContract',
  //           query: { businessFlowId: item.businessFlowId },
  //         });
  //       } else if (res.resultData === 'default') {
  //         this.$router.push({ path: '/' });
  //       } else {
  //         let link = document.createElement('a');
  //         link.style.display = 'none';
  //         link.href = res.resultData;
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);
  //       }
  //     }
  //   });
  // },
};
</script>

<style lang="less" scoped></style>
